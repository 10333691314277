import { useNavigate } from "react-router-dom";
import { useAuth } from "../../state/auth";
import { useEffect } from "react";

const PrivateRoute = ({ children }) => {
  const navigate = useNavigate();
  const { isLoading, isLoggedIn } = useAuth();

  useEffect(() => {
    if (!isLoading && !isLoggedIn) {
      return navigate("/login");
    }
  }, [isLoggedIn, isLoading, navigate]);

  return <> {children} </>;
};

export default PrivateRoute;
