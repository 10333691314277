import { useState, useEffect } from "react";
import axios from "axios";

const DirectoryPage = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [results, setResults] = useState([]);

  useEffect(() => {
    const performSearch = () => {
      if (searchTerm.length >= 3) {
        axios
          .get(`/php/directory_search.php?search=${searchTerm}`)
          .then(response => {
            setResults(response.data);
          })
          .catch(error => {
            console.error("Error fetching data: ", error);
            setResults([]);
          });
      } else {
        setResults([]);
      }
    };

    const timerId = setTimeout(() => {
      performSearch();
    }, 500);

    return () => {
      clearTimeout(timerId);
    };
  }, [searchTerm]);

  return (
    <div>
      <input
        type="text"
        value={searchTerm}
        onChange={e => setSearchTerm(e.target.value)}
        placeholder="Entrez au moins 3 caractères..."
      />
      <table className="responsive-table">
        <thead>
          <tr>
            <th>Prénom Nom</th>
            <th>Téléphone - LinkedIn</th>
            <th>Email</th>
          </tr>
        </thead>
        <tbody>
          {results.map((user, index) => (
            <tr key={index}>
              <td>
                <strong>{`${user.TFirst_Name} ${user.TLast_Name}`}</strong>
              </td>
              <td>
                {user.Phone}
                {user.Linkedin && user.Linkedin.startsWith("https://") && (
                  <span>
                    {" "}
                    -{" "}
                    <a
                      href={user.Linkedin}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img
                        src="/media/linkedin-icon.png"
                        alt="LinkedIn"
                        width="20"
                        height="20"
                      />
                    </a>
                  </span>
                )}
              </td>
              <td>{user.Email}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default DirectoryPage;
