import TelegramLoginButton from "react-telegram-login";

const LoginPage = () => {
  console.log();
  return (
    <>
      <p>Pour vous identifier rendez-vous sur le groupe Telegram puis entrez la commande <code>/app</code> ou utiliser le bouton Telegram sur cette page.</p>
      <TelegramLoginButton
        dataAuthUrl="/php/authorize.php"
        botName="NWayTelegramBot"
      ></TelegramLoginButton>
    </>
  );
};

export default LoginPage;
