const HomePage = () => {
  return (
    <div>
      Home Page Content
      <h1>
        Associés de N'Way, nous sommes convaincus que le décloisonnement des
        frontières en Normandie est un puissant facteur de pérennité, de
        compétitivité et d'attractivité. Les perspectives de développement
        économique en période de crise incitent plus que jamais les
        entrepreneurs à favoriser les coopérations et l'engagement collectif.
      </h1>
    </div>
  );
};

export default HomePage;
