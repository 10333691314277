import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import "./App.css";
import HomePage from "./pages/home";
import DirectoryPage from "./pages/directory";
import AgendaPage from "./pages/agenda";
import NewsPage from "./pages/news";

import Navigation from "./components/Navigation";
import PrivateRoute from "./components/PrivateRoute";
import { AuthProvider } from "./state/auth";
import LoginPage from "./pages/login";

const App = () => {
  return (
    <AuthProvider>
      <Router>
        <Navigation />
        <Routes>
          <Route path="/login" element={<LoginPage />} />
          <Route
            path="/"
            element={
              <PrivateRoute>
                <HomePage />
              </PrivateRoute>
            }
          />
          <Route
            path="/annuaire"
            element={
              <PrivateRoute>
                <DirectoryPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/agenda"
            element={
              <PrivateRoute>
                <AgendaPage />
              </PrivateRoute>
            }
          />
          <Route
            path="/news"
            element={
              <PrivateRoute>
                <NewsPage />
              </PrivateRoute>
            }
          />
        </Routes>
      </Router>
    </AuthProvider>
  );
};

export default App;
